export default {
  colors: {
    background: "#FFFFFF",

    primary: {
      pink: "#DF1098",
      purple: "#7C17E2",
    },

    secondary: {
      purple: "#E3C7FF",
      health_green: "#018762",
      txt_green: "#018383",
      light_green: "#F3FFF9",
    },

    gray: {
      dark: "#515151",
      mid: "#6a6a6a",
      light: "#F4F4F4",
      text_base: "#1F1F1F",
    },

    auxiliary: {
      black: "#220F1A",
      link: "#0072EC",
      alert: "#EC0E00",
      pink: "#FCD7ED",
      green: "#B0E0D3",
      success: "#0B7944",
    },

    gradient: {
      pink: "linear-gradient(105.43deg, #DF1098 14.39%, #BA17E2 73.71%)",
      green: "linear-gradient(105.43deg, #018383 14.39%, #014687 84.69%)",
      background: "linear-gradient(105.43deg, #FFFFFF 14.39%, #F9F3FF 84.69%)",
    },
  },
}
