import React from "react"

const Linkedin = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9997 5.33301H7.99967C6.52692 5.33301 5.33301 6.52692 5.33301 7.99967V23.9997C5.33301 25.4724 6.52692 26.6663 7.99967 26.6663H23.9997C25.4724 26.6663 26.6663 25.4724 26.6663 23.9997V7.99967C26.6663 6.52692 25.4724 5.33301 23.9997 5.33301Z"
        stroke="url(#paint0_linear_164:24354)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 14.667V21.3337"
        stroke="url(#paint1_linear_164:24354)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 10.667V10.6803"
        stroke="url(#paint2_linear_164:24354)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.3337V14.667"
        stroke="url(#paint3_linear_164:24354)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3333 21.3337V17.3337C21.3333 16.6264 21.0524 15.9481 20.5523 15.448C20.0522 14.9479 19.3739 14.667 18.6667 14.667C17.9594 14.667 17.2811 14.9479 16.781 15.448C16.281 15.9481 16 16.6264 16 17.3337"
        stroke="url(#paint4_linear_164:24354)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_164:24354"
          x1="9.24967"
          y1="5.33301"
          x2="27.0335"
          y2="10.2421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_164:24354"
          x1="10.8506"
          y1="14.667"
          x2="11.7462"
          y2="14.7041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_164:24354"
          x1="10.8506"
          y1="10.667"
          x2="10.8527"
          y2="10.7102"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_164:24354"
          x1="16.1836"
          y1="14.667"
          x2="17.0792"
          y2="14.7041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_164:24354"
          x1="16.9792"
          y1="14.667"
          x2="21.5414"
          y2="15.6745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Linkedin
