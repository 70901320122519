import React from "react"
import { Link, navigate } from "gatsby"

import { Container, WrapperBtns } from "./styles"
import CogIcon from "../../../assets/icons/Cog"

export default function Header({ hasVoltar = false, isLogged = false }) {
  function logout() {
    if(isLogged){
      isLogged = false;
      navigate("/");
    }
  }

  return (
    <Container id="header">
      <div className="logo">
        <Link to="/">Lacrei Saúde</Link>
      </div>
      <WrapperBtns>
        {isLogged && <button onClick={logout}>Finalizar sessão</button>}

        {hasVoltar && <button onClick={() => navigate(-1)}>Voltar</button>}
        <Link to="/ajuda">
          <button>Ajuda</button>
        </Link>
      </WrapperBtns>
    </Container>
  )
}
