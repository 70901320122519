import styled from "styled-components"

export const Container = styled.footer`
  width: 100%;
  max-width: 68.75rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  hr {
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.colors.auxiliary.green};
    padding-bottom: 1.313rem;
  }

  .menu {
    li {
      height: 1.5rem;
      display: inline-block;
      margin-right: 1.5rem;

      a {
        color: ${({ theme }) => theme.colors.gray.text_base};
      }
    }
  }
  .social-footer-bottom {
    display: flex;
    gap: 5rem;
    margin-top: 1rem;
    margin-bottom: 1.375rem;
    align-items: center;
  }
  .social {
    ul {
      height: 2rem;
    }

    ul li {
      height: 2rem;
      display: inline-block;

      a {
        margin-right: 2rem;
      }
    }
    ul li:last-child {
      a {
        margin-right: 0;
      }
    }
  }
  .mobile-only {
    display: none;
  }
  .footer-bottom {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
      color: ${({ theme }) => theme.colors.gray.dark};
      line-height: 1.125rem;
    }

    a {
      border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.385rem;
      cursor: pointer;
      transition: 0.2s;

      :hover {
        opacity: 0.7;
      }

      svg {
        color: ${({ theme }) => theme.colors.secondary.health_green};
      }
    }

    .button {
      p,
      a {
        color: ${({ theme }) => theme.colors.gray.dark};
        font-size: 0.875rem;
        line-height: 21px;

        + p {
          margin-top: 0.5rem;
        }
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray.dark};
      font-size: 0.75rem;
    }

    button {
      width: 3rem;
      height: 3rem;

      svg {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    background-color: #f3fff9;
    padding: 1rem 2.5rem;

    .social-footer-bottom {
      gap: 2.75rem;
      flex-direction: column;
    }
    hr {
      display: none;
    }
    .mobile-only {
      display: block;
    }
    .menu {
      font-weight: 700;
    }
    .menu ul {
      gap: 1.75rem;
    }
    .social ul {
      gap: 2.75rem;
    }
    .menu,
    .social {
      ul {
        height: auto;
        display: flex;
        flex-direction: column;
      }
      li {
        height: auto;
      }
      li a {
        color: ${({ theme }) => theme.colors.secondary.health_green} !important;
        .social-span {
          display: flex;
          align-items: center;
          gap: 1.75rem;
        }
      }
    }
  }
`

export const Cnpj = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray.mid};
`
