import React from "react"

const Instagram = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.333 5.33301H10.6663C7.72082 5.33301 5.33301 7.72082 5.33301 10.6663V21.333C5.33301 24.2785 7.72082 26.6663 10.6663 26.6663H21.333C24.2785 26.6663 26.6663 24.2785 26.6663 21.333V10.6663C26.6663 7.72082 24.2785 5.33301 21.333 5.33301Z"
        stroke="url(#paint0_linear_164:24347)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z"
        stroke="url(#paint1_linear_164:24347)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10V10.001"
        stroke="url(#paint2_linear_164:24347)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_164:24347"
          x1="9.24967"
          y1="5.33301"
          x2="27.0335"
          y2="10.2421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_164:24347"
          x1="13.4687"
          y1="12"
          x2="20.1377"
          y2="13.8409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_164:24347"
          x1="22.1836"
          y1="10"
          x2="22.1836"
          y2="10.0032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Instagram
