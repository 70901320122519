import React from "react"

const Mail = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3333 6.66699H6.66667C5.19391 6.66699 4 7.8609 4 9.33366V22.667C4 24.1398 5.19391 25.3337 6.66667 25.3337H25.3333C26.8061 25.3337 28 24.1398 28 22.667V9.33366C28 7.8609 26.8061 6.66699 25.3333 6.66699Z"
        stroke="url(#paint0_linear_164:24363)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 9.33301L16 17.333L28 9.33301"
        stroke="url(#paint1_linear_164:24363)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_164:24363"
          x1="8.40625"
          y1="6.66699"
          x2="27.5288"
          y2="13.4538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_164:24363"
          x1="8.40625"
          y1="9.33301"
          x2="21.1784"
          y2="19.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Mail
