import React from "react"
import { Link } from "gatsby"
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp"

import Facebook from "../../../assets/icons/Facebook.jsx"
import Instagram from "../../../assets/icons/Instagram.jsx"
import Linkedin from "../../../assets/icons/Linkedin.jsx"
import Mail from "../../../assets/icons/Mail.jsx"

import colors from "../../../styles/themes/main"
import { Cnpj, Container } from "./styles"

export default function Footer({ hasButtonUp = false }) {
  return (
    <Container>
      <hr />
      <div className="sitemap">
        <div className="menu">
          <ul>
            <li>
              <Link to="/">Lacrei Saúde</Link>
            </li>
            <li>
              <Link to="/">Política de Privacidade</Link>
            </li>
            <li>
              <Link to="/">Termos de Uso</Link>
            </li>
            <li>
              <Link to="/">Suporte</Link>
            </li>
          </ul>
        </div>
        <div className="social-footer-bottom">
          <div className="social">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/lacrei.saude"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-span">
                    <Facebook color={colors.colors.secondary.health_green} />
                    <span className="mobile-only">Facebook</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/lacrei.saude/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-span">
                    <Instagram color={colors.colors.secondary.health_green} />
                    <span className="mobile-only">Instagram</span>
                  </span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/lacrei/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="social-span">
                    <Linkedin color={colors.colors.secondary.health_green} />
                    <span className="mobile-only">Linkedin</span>
                  </span>
                </a>
              </li>
              <li>
                <a href="mailto:contato@portallacrei.com.br" rel="noreferrer">
                  <span className="social-span">
                    <Mail color={colors.colors.secondary.health_green} />
                    <span className="mobile-only">E-mail</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-bottom">
            <Cnpj>CNPJ: 35.328.975/0001-28</Cnpj>
            {hasButtonUp && (
              <a href="#header">
                <KeyboardArrowUp />
              </a>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
