import React from "react"

const Facebook = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33301 13.3333V18.6667H13.333V28H18.6663V18.6667H22.6663L23.9997 13.3333H18.6663V10.6667C18.6663 10.313 18.8068 9.97391 19.0569 9.72386C19.3069 9.47381 19.6461 9.33333 19.9997 9.33333H23.9997V4H19.9997C18.2316 4 16.5359 4.70238 15.2856 5.95262C14.0354 7.20286 13.333 8.89856 13.333 10.6667V13.3333H9.33301Z"
        stroke="url(#paint0_linear_164:24342)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_164:24342"
          x1="12.0257"
          y1="4"
          x2="24.8196"
          y2="6.15823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Facebook
