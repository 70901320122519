import styled from "styled-components"

export const Container = styled.header`
  width: 100%;
  max-width: 68.75rem;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  .logo a {
    font-weight: 700;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.secondary.health_green};
    transition: 0.2s;

    :hover {
      opacity: 0.7;
    }
  }

  button {
    font-size: 1.125rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.secondary.health_green};
    border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
    width: 7.125rem;
    height: 3rem;
    border-radius: 8px;
    transition: 0.2s;

    :hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 1020px) {
    padding: 0 2.5rem;
    .logo a {
      font-size: 1.5rem;
    }

    button {
      font-size: 0.875rem;
    }
  }
`

export const WrapperBtns = styled.div`
  display: flex;
  grid-gap: 1rem;

  button {
    width: 100%;
    padding: 0 1rem;
  }

  button.cogIcon {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
